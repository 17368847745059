import { localStorage } from '@elparking/utils'
import moment from 'moment-timezone'

export function areCookiesAccepted() {
  return localStorage.get('ParkingDoor:cookies') === '2'
}

export function isCountrySelected() {
  return localStorage.get('ElParking:country') === '1'
}

export function setCountrySelected() {
  localStorage.save(
    'ElParking:country',
    '1',
    moment().add(7, 'days').utc().format()
  )
}
