import { hot } from 'react-hot-loader/root'
import React from 'react'
import { IntlProvider } from 'react-intl'
import CountrySelectionModal from '@elparking/country-selection-modal'
import { defaultLocale, currentLocale } from 'commons/js/locale'
import { messages } from 'commons/js/messages'
import theme from './theme'
import Routes from 'commons/js/routes/routes'
import { navigateTo } from 'commons/js/services/navigate'
import { getIpCountry } from 'country-selection/js/services/countryModalChecker'
import { EPProvider } from '@elparking/components'
import convertConstantToEPProviderFormat from 'commons/js/util/coverterGlobalConfig'
import { getConstant } from 'commons/js/constants'

const App = () => {
    return (
        <IntlProvider defaultLocale={defaultLocale} locale={currentLocale} messages={messages[currentLocale]}>
            <EPProvider theme={theme} {...convertConstantToEPProviderFormat()}>
                <CountrySelectionModal
                  onSubmit={(selectedLanguage, selectedCountry) => { navigateTo(Routes.homePath({}, selectedLanguage, selectedCountry)) }}
                  getIpCountry={getIpCountry}
                  getAcceptedCountries={() => Promise.resolve(Object.values(getConstant('COMPATIBLE_COUNTRIES')))}
                />
            </EPProvider>
        </IntlProvider>
    )
}

export default hot(App)
