import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { setCountrySelected } from '../../services/countryModalChecker'
import { Image, Flex, Box, Button, Copy, Modal, Dropdown } from '@elparking/components'

import messages from './messages'

import bra from './flag/BRA.svg'
import col from './flag/COL.svg'
import cri from './flag/CRI.svg'
import ecu from './flag/ECU.svg'
import esp from './flag/ESP.svg'
import fra from './flag/FRA.svg'
import usa from './flag/USA.svg'
import and from './flag/AND.svg'

import languageIcon from './ic_language.svg'

const mapFlags = {
    'BRA': bra,
    'COL': col,
    'CRI': cri,
    'ECU': ecu,
    'ESP': esp,
    'FRA': fra,
    'USA': usa,
    'AND': and
}

class CountrySelectionModalConnected extends Component {
    static propTypes = {
        intl: PropTypes.object,
        countryOptions: PropTypes.array,
        selectedCountry: PropTypes.string,
        setCountrySelected: PropTypes.func,
        componentEPModal: PropTypes.func,
        onSubmit: PropTypes.func,
    }

    static defaultProps = {
        setCountrySelected,
        componentEPModal: Modal,
    }

    constructor (props) {
        super(props)

        const selectedCountry = this.findDefaultSelectedCountry()

        this.state = {
            selectedCountry,
            selectedLanguage: this.getDefaultLanguage(selectedCountry),
        }

        this.selectCountry = this.selectCountry.bind(this)
        this.selectLanguage = this.selectLanguage.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    getDefaultLanguage (country) {
        return country && country.languages && country.languages.length ? { value: country.languages[0].code } : null
    }

    findDefaultSelectedCountry () {
        const { selectedCountry, countryOptions } = this.props

        return countryOptions.find((item) => {
            return selectedCountry.toLowerCase() === item.value.toLowerCase()
        })
    }

    selectCountry (country) {
        this.setState(() => ({
            selectedCountry: country,
            selectedLanguage: this.getDefaultLanguage(country),
        }))
    }

    selectLanguage (language) {
        this.setState(() => ({
            selectedLanguage: language,
        }))
    }

    onSubmit () {
        const { selectedCountry, selectedLanguage } = this.state
        const { onSubmit } = this.props

        this.props.setCountrySelected()
        onSubmit(selectedLanguage.value, selectedCountry.value.toLowerCase())
        return false
    }

    getLangOptions () {
        const {selectedCountry} = this.state

        return selectedCountry.languages.map(({code: value, name: label}) => ({value, label}))
    }

    renderCountryOption (option) {
        return (
            <Flex alignItems='center'>
                <Box px={'0.2em'} height='24px'>
                    <Image src={mapFlags[option.value]} located={false} alt="elParking"/>
                </Box>
                <Box px={'0.2em'}>
                    {option.label}
                </Box>
            </Flex>
        )
    }

    renderLanguageOption (option) {
        return (
            <Flex alignItems='center'>
                {option.label}
            </Flex>
        )
    }

    renderLanguageSelectedOption (option) {
        return (
            <Flex alignItems='center'>
                <Box px={'0.2em'} height='24px'>
                    <Image src={languageIcon} located={false} alt="elParking"/>
                </Box>
                <Box px={'0.2em'}>
                    {option.label}
                </Box>
            </Flex>
        )
    }

    renderDropdownInlineOption (option) {
        return (option.label)
    }

    render () {
        const { intl, countryOptions, componentEPModal: ComponentEPModal } = this.props
        const modalTitle = intl.formatMessage(messages.modalTitleCountryAndLanguage)

        if (!this.state.selectedCountry) {
            return null
        }

        return (
            <ComponentEPModal
              showCloseButton={false}
              title={modalTitle}
              label={modalTitle}
              width='600px'
              isShown>
                <Flex mb={'1em'} justifyContent='center'>
                    <Copy fontWeight='light' fontSize='medium' padding='0' color='main'>
                        <FormattedMessage defaultMessage='Accederás al contenido específico de esa localización en el idioma que has elegido.' />
                    </Copy>
                </Flex>
                <Flex flexDirection={['column', 'row']} justifyContent='space-between'>
                    <Box flex='auto' pr={['0', '0.5em']} py={'0.5em'}>
                        <Dropdown
                          dataTest='select-country-dropdown'
                          renderSelected={this.renderCountryOption}
                          renderOptionNative={this.renderDropdownInlineOption}
                          renderOption={this.renderCountryOption}
                          options={countryOptions}
                          onChange={this.selectCountry}
                          defaultValue={this.state.selectedCountry.value}
                        />
                    </Box>
                    <Box flex='auto' px={['0', '0.5em']} py={'0.5em'}>
                        <Dropdown
                          dataTest='select-language-dropdown'
                          renderSelected={this.renderLanguageSelectedOption}
                          renderOptionNative={this.renderDropdownInlineOption}
                          renderOption={this.renderLanguageOption}
                          options={this.getLangOptions()}
                          onChange={this.selectLanguage}
                          defaultValue={this.state.selectedLanguage.value}
                          value={this.state.selectedLanguage.value}
                        />
                    </Box>
                    <Box py={'0.5em'} pl={['0', '0.5em']}>
                        <Button dataTest='continue-button' type='right' size='xLarge' color='white' onClick={this.onSubmit} width='100%'>
                            {intl.formatMessage(messages.submitButton)}
                        </Button>
                    </Box>
                </Flex>
            </ComponentEPModal>
        )
    }
}

export default injectIntl(CountrySelectionModalConnected)
