import { defineMessages } from 'react-intl'

export default defineMessages({
    modalTitleCountry: {
        defaultMessage: 'Selecciona el país',
    },
    modalTitleCountryAndLanguage: {
        defaultMessage: 'Selecciona el país e idioma',
    },
    modalDescriptionCountry: {
        defaultMessage: 'Accederás al contenido específico de esa localización.',
    },
    modalDescriptionCountryAndLanguage: {
        defaultMessage: 'Accederás al contenido específico de esa localización en el idioma que has elegido.',
    },
    submitButton: {
        defaultMessage: 'Continuar',
    },
})
