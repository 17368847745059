import React from 'react'
import PropTypes from 'prop-types'
import CountrySelectionModal from './src/components/CountrySelectionModal'
import CountrySelectionModalConnected from './src/components/CountrySelectionModal/CountrySelectionModalConnected'
import { useConfig } from '@elparking/components'

function renderModal (countries, selectedCountry, onSubmit) {
    return (<CountrySelectionModalConnected 
        countryOptions={countries} 
        selectedCountry={selectedCountry}
        onSubmit={onSubmit}
    />)
}

CountrySelectionModalWithConfig.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    getIpCountry: PropTypes.func.isRequired,
    getAcceptedCountries: PropTypes.func.isRequired
}

function CountrySelectionModalWithConfig({
    onSubmit,
    getIpCountry,
    getAcceptedCountries
}) {
    const country = useConfig('country')
    return <CountrySelectionModal 
        renderModal={renderModal} 
        webCountry={country}
        onSubmit={onSubmit}
        getAcceptedCountries={getAcceptedCountries}
        getIpCountry={getIpCountry}
    />
}

export default CountrySelectionModalWithConfig
