import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { isCountrySelected, areCookiesAccepted } from '../../services/countryModalChecker'
import { mapCountryForDropdown } from './mapper'
import { WHITELISTED_COUNTRY_SELECTION_MODAL } from '../../utils/constants'

class CountrySelectionModal extends Component {
    static propTypes = {
        getIpCountry: PropTypes.func,
        getAcceptedCountries: PropTypes.func,

        isCountrySelected: PropTypes.func,
        areCookiesAccepted: PropTypes.func,

        renderModal: PropTypes.func.isRequired,

        countries: PropTypes.array,
        selectedCountry: PropTypes.number,
        webCountry: PropTypes.string.isRequired,
        ipCountry: PropTypes.string,

        onSubmit: PropTypes.func.isRequired
    }

    static defaultProps = {
        isCountrySelected,
        areCookiesAccepted,
    }

    constructor (props) {
        super(props)

        this.state = {
            ipCountry: props.ipCountry,
            webCountry: props.webCountry,
            countries: props.countries,
            selectedCountry: props.selectedCountry,
        }
    }

    checkLocalStorage () {
        return this.props.areCookiesAccepted() && !this.props.isCountrySelected()
    }

    componentDidMount () {
        const { ipCountry } = this.state

        if (this.checkLocalStorage()) {
            if (!ipCountry) {
                this.props.getIpCountry().then((response) => {
                    if (response.countryCodeISO3) {
                        this.setState(() => ({
                            ipCountry: response.countryCodeISO3.toLowerCase(),
                        }))
                    }
                })
            }
        }
    }

    componentDidUpdate () {
        const { webCountry, ipCountry, countries } = this.state

        if (ipCountry && ipCountry !== webCountry && !countries) {
            this.props.getAcceptedCountries(WHITELISTED_COUNTRY_SELECTION_MODAL).then((countries) => {
                this.setState(() => ({
                    countries: mapCountryForDropdown(countries),
                }))
            })
        }
    }

    render () {
        const { webCountry, ipCountry, countries } = this.state
        const { onSubmit } = this.props
        if (this.checkLocalStorage() && ipCountry && ipCountry !== webCountry && countries) {
            return this.props.renderModal(countries, ipCountry, onSubmit)
        }

        return (<div />)
    }
}

export default injectIntl(CountrySelectionModal)
